export default {
  firebaseConfig: {
    apiKey: "AIzaSyCN-kH8I1oD4uF0iarkwa-Eg5rbbN_0ne0",
    authDomain: "cal-firewatch.firebaseapp.com",
    databaseURL: "https://cal-firewatch.firebaseio.com",
    projectId: "cal-firewatch",
    storageBucket: "cal-firewatch.appspot.com",
    messagingSenderId: "623569625100",
    appId: "1:623569625100:web:d5d742b214106ec18c4e29",
    measurementId: "G-3H3LZ0GBXN",
  },
  mapKey: "AIzaSyCN-kH8I1oD4uF0iarkwa-Eg5rbbN_0ne0",
};
